.modal-nueva-actividad::-webkit-scrollbar {
    width: 4px;
}
 
.modal-nueva-actividad::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.modal-nueva-actividad::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}



/*ponderar-acordion*/