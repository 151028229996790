@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-inscripcion-cliente{
    background-color: #7389AE;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.recuadro-formulario{
    border-radius: 10%;
    width: 50%;
    height: 90%;
    background-color: black;
}
.columna{
    border: 5px solid black;
}
.columna-formulario{
    position:relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.columna-formulario::-webkit-scrollbar {
    width: 4px;
}
 
.columna-formulario::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.columna-formulario::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}
.container{
    width: 100%;
    height: 100%;
}

.font-bold{
    font-family: 'Montserrat', sans-serif;
}
.font-normal{
    font-family: 'Helvetica', 'Arial', sans-serif;
}


#body-modal::-webkit-scrollbar {
    width: 4px;
}
 
#body-modal::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
#body-modal::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}

.poster-info{
    background-image: url('https://ik.imagekit.io/dmbtwqtjj/web_background_Mesa_de_trabajo_1_tQi_6omaZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658425067026');
    background-repeat: no-repeat;
    background-attachment: calc();
    background-position: center center;
    background-size:calc();
}

.blanco{
    color: white;
}

.informacion-derecha{
    width: 100%;
    height: 90%;
    margin-top: 10%;
}
.contenedor-inscripcion-cliente::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}
 
.contenedor-inscripcion-cliente::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.contenedor-inscripcion-cliente::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}

.participantes-list .accordion-item {
    margin-top: 15px;
    background-color: transparent !important;
    border-color: white !important;
    color: white;
}