@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-login{
    background-image: url('./PORTADA-USUARIOS\ \(1\).jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;    
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.col-formulario{
    padding-left: 5%;
    padding-right: 5%;
    height: 100%;
    overflow: hidden;

}
.col-formulario::before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-left: -0.5ch;
}

.container-formulario{
    display: inline-block;
    vertical-align: middle;
    padding: 10%;
    width: 100%;
    background-color: #E0E0E2;
}



.container-form{
    display: inline-block;
    vertical-align: middle;
    padding: 10%;
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    background-color: #E0E0E2;
}



.container-form::-webkit-scrollbar {
    width: 5px;
}

.container-form::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.container-form::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}



.tab-recovery {
    color: black !important;
}

.tab-recovery:hover {
    color: black !important;
    border-color: #617785 !important;
    border: 1px solid #617785 !important;
}

.tab-recovery.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color: white !important;
}
