.contenedor-inicio {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/PORTADA-1-_UtSeRnmii.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650642947423');
}

.fila-botones {
    position: relative;
    top: 40%;

}

.contenedor-botones-inicio {
    overflow-y: scroll;

}

.titulo-inicio {
    color: white;
}

.columna-botones {
    cursor: pointer;
}

.imagen-boton {
    margin-top: 15%;
    height: 290px;
    border-radius: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 15%;
}

.imagen-boton:hover {
    box-shadow: 0 0 20px 10px rgba(0, 140, 186, 0.5);
}

.contenedor-botones-inicio::-webkit-scrollbar {
    width: 0px;
}
 
.contenedor-botones-inicio::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.contenedor-botones-inicio::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}
