#body-modal-edit-participantes::-webkit-scrollbar {
    width: 4px;
}

#body-modal-edit-participantes::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

#body-modal-edit-participantes::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.modal-body-acordion .accordion-button.collapsed {
    color:white !important;
    background-color: #617785 !important;
    border-color: transparent !important;
}
.modal-body-acordion .accordion-button {
    color:black !important;
    background-color: white !important;
    border-color: transparent !important;
}

.modal-body-acordion .accordion-item {
    background-color: transparent !important;
    border-color: transparent !important;
}

.modal-body-acordion .accordion-button :focus{
    box-shadow: 0 0 0 0.25rem rgb(97,119,133/25%) !important;
}