.layout-custom::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .layout-custom::-webkit-scrollbar-track {
    background: #B5BAD0;
  }
  
  /* Handle */
  .layout-custom::-webkit-scrollbar-thumb {
    background: #1e3d52;
  }
  
  /* Handle on hover */
  .layout-custom::-webkit-scrollbar-thumb:hover {
    background: #416788;
  }