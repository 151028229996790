.frame-diploma {}

@media screen and (max-width: 500px) {
    .frame-diploma {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .frame-diploma {
        display: none;
    }
}