.columna-eventos-procesadas{
    overflow-y: scroll;
    height: 50vh;
}

.columna-eventos-procesadas::-webkit-scrollbar {
    width: 8px;
}

.columna-eventos-procesadas::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.columna-eventos-procesadas::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}
