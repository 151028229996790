@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-view-inscripcion{
    width: 100vw;
    height: 100vh;
    background-color: #e0e0e2;
    padding-top:40vh;
    background-image: url('./PORTADA-LOGOS\ \(1\).jpg');
    background-repeat: no-repeat;
    background-attachment: calc();
    background-position: center center;
    background-size: cover;
    overflow-y: scroll;
}
.title-contancia{
    font-family: 'Montserrat', sans-serif;
    color:white;
}
.columna-formulario-view{
    overflow-y: scroll;
    height: 48vh;
}


.columna-formulario-view::-webkit-scrollbar {
    width: 4px;
}
 
.columna-formulario-view::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.columna-formulario-view::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}


.contenedor-view-inscripcion::-webkit-scrollbar {
    width: 4px;
}
 
.contenedor-view-inscripcion::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.contenedor-view-inscripcion::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}

.view-detalle{
    overflow-y: scroll;
    height: 48vh;
}

.view-detalle::-webkit-scrollbar {
    width: 4px;
}
 
.view-detalle::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.view-detalle::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}