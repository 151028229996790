@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-portal {
    width: 100vw;
    height: 100vh;
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/fondo_para_ficha_Asesores_yAilhCATs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652366171213');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow-y: scroll;
}

.columna-azul {
    background-color: blue;
}

#arriba {
    height: 45px;
    width: 11.9px;
}

#abajo {
    height: 45px;
    width: 11.9px;
    position: relative;
}

#informacion {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 60vh;
}

.contenedor-foto {
    width: 300px;
    height: 300px;
    border-radius: 300px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

.correos {
    overflow-x: scroll;
}

.logos {
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/logotipos_J3ALOjtXZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652370601113');
    background-repeat: no-repeat;
    background-position: center left;
    overflow: hidden;
}


/*
 style={{ fontWeight: '400', textAlign: 'left', fontSize: '60px', letterSpacing: '5px' }}

*/
#informacion::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

#informacion::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

#informacion::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 100px;
}

.correos::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.correos::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

.correos::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 100px;
}



.titulo-entidad {
    text-align: left;
    font-weight: 400;
}

.titulo-contacto {
    width: 40%;
    height: 100%;

}

.titulo-contacto:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    width: 8%;
    /* Change this to whatever width you want. */
    margin-left: 27%;
    padding-top: 2px;
    border-bottom: 5px solid blue;
    /* This creates the border. Replace black with whatever color you want. */
}

@media screen and (max-width: 1200px) {

    #columna-foto {
        display: none;
    }

    #columnas-azul {
        display: none;
    }

    #foto-responsive {
        display: block;
    }

    .logos {
        background-size: 80%;
        width: 100%;
        height: 100%;
    }

    .imagen-icono {
        width: 30px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
    }

    .nombres {
        text-align: left;
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 5px;
    }

    .apellidos {
        font-weight: 400;
        text-align: left;
        font-size: 35px;
        letter-spacing: 5px;
    }


    .contenedor-puesto {
        background-color: blue;
        height: 6vh;
    }

    .titulo-puesto {
        color: white;
        line-height: 6vh;
        text-align: left;
        font-size: small;
        font-family: 'Montserrat', sans-serif;
    }

    .fila-control-height {
        height: 8vh;
    }

    .logos {
        background-position: center center;
    }
}

@media screen and (max-width: 500px) {
    .contenedor-puesto {
        background-color: blue;
        height: 4vh;
    }

    .titulo-puesto {
        color: white;
        line-height: 4vh;
        text-align: left;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .titulo-entidad {
        text-align: left;
        font-weight: 400;
        font-size: 18px;
    }

    .titulo-contacto:after {
        content: "";
        /* This is necessary for the pseudo element to work. */
        display: block;
        /* This will put the pseudo element on its own line. */
        width: 10%;
        /* Change this to whatever width you want. */
        margin-left: 32%;
        padding-top: 2px;
        border-bottom: 5px solid blue;
        /* This creates the border. Replace black with whatever color you want. */
    }

    .fila-control-height {
        height: 5vh;
    }

    #correos {
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 1800px) {
    #abajo {
        bottom: -40%;
    }

    .fila-control-height {
        height: 2vh;
    }

    #correos {
        margin-bottom: 10px;
    }
}

@media screen and (max-height: 500px) {
    #informacion {
        height: 40vh;
    }

    .logos {
        background-size: 70%;
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 1200px) {
    #correos {
        margin-bottom: 20px;
    }

    .fila-control-height {
        height: 8vh;
    }

    #foto-responsive {
        display: none;
    }

    .logos {
        background-size: cover;
        width: 70%;
        height: 70%;
    }

    .imagen-icono {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40%;
    }

    .nombres {
        text-align: left;
        font-size: 60px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 5px;
    }

    .apellidos {
        font-weight: 400;
        text-align: left;
        font-size: 60px;
        letter-spacing: 5px;
    }


    .contenedor-puesto {
        background-color: blue;
        height: 6vh;
    }

    .titulo-puesto {
        color: white;
        line-height: 6vh;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
    }
}

.link:link {
    color: white;
}

/* visited link */
.link:visited {
    color: white;
}

/* mouse over link */
.link:hover {
    color: white;
}

/* selected link */
.link:active {
    color: white;
}