@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-gafete {
    font-family: 'Montserrat', sans-serif;
    width: 100vw;
    height: 100vh;
    background-image: url('https://ik.imagekit.io/dmbtwqtjj/web_background_Mesa_de_trabajo_1_tQi_6omaZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658425067026');
    background-repeat: no-repeat;
    background-attachment: calc();
    background-position: center center;
    background-size: calc();
    overflow-y: scroll;
    overflow-x: hidden;
}

.contenedor-gafete::-webkit-scrollbar {
    width: 5px;
}

.contenedor-gafete::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.contenedor-gafete::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}