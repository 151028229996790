.modal-nueva-actividad::-webkit-scrollbar {
    width: 4px;
}
 
.modal-nueva-actividad::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.modal-nueva-actividad::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}


.tab-actividades {
    color: black !important;
}

.tab-actividades:hover {
    color: white !important;
    border-color: #617785 !important;
}

.tab-actividades.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color: white !important;
}

.tab-actividades.detalle:hover {
    color: #1e3d52 !important;
}

.tab-actividades.active.detalle:hover {
    color: white !important;
}

.ponderar-acordion .accordion-button.collapsed {
    color:white !important;
    background-color: #617785 !important;
    border-color: transparent !important;
}
.ponderar-acordion .accordion-button {
    color:black !important;
    background-color: white !important;
    border-color: transparent !important;
}

.ponderar-acordion .accordion-item {
    background-color: transparent !important;
    border-color: transparent !important;
}

.ponderar-acordion .accordion-button :focus{
    box-shadow: 0 0 0 0.25rem rgb(97,119,133/25%) !important;
}
/*ponderar-acordion*/