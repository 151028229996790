@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

.preguntas-crear-formulario {
    overflow-y: scroll;
}

.contenedor-respuestas-public {
    width: 100vw;
    height: 100vh;
    background-color: #1e3d52;
    overflow: hidden;
    padding: 2%;
    overflow-y: scroll;
}

.preguntas-crear-formulario::-webkit-scrollbar {
    width: 5px;
}

.preguntas-crear-formulario::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.preguntas-crear-formulario::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}


#contenedor-formulario-cliente::-webkit-scrollbar {
    width: 5px;
}

#contenedor-formulario-cliente::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

#contenedor-formulario-cliente::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.contenedor-respuestas-public::-webkit-scrollbar {
    width: 5px;
}

.contenedor-respuestas-public::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.contenedor-respuestas-public::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.row-tabla-respuestas {
    height: 300px;
    overflow: scroll;
}

.row-tabla-respuestas::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.row-tabla-respuestas::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.row-tabla-respuestas::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}


.row-columnas .col {}

.columna-info-form {
    margin-bottom: 45px;
}

.columna-info-form .row {
    height: 100%;
    text-align: center;
    display: flex;
    place-items: center;
}

.columna-info-form.titulo {
    border-radius: 75px;
    padding: 5%;
    box-shadow: rgba(30, 61, 82, 0.4) -5px 5px, rgba(30, 61, 82, 0.3) -10px 10px, rgba(30, 61, 82, 0.2) -15px 15px, rgba(30, 61, 82, 0.1) -20px 20px, rgba(30, 61, 82, 0.05) -25px 25px;
}
