@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-crud-eventos{
    width: 100%;
    height: 60vh;
    background-color: #1e3d52;
    color:white;
    overflow-y: scroll;
}

.segmento-formulario{
    padding-top:25px;
}

.contenedor-crud-eventos::-webkit-scrollbar {
    width: 4px;
}
 
.contenedor-crud-eventos::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.contenedor-crud-eventos::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}
