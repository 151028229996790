
.contenedor-no-autorizado {
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/PORTADA_ACCESO-RESTINGIDO__1__EVGdFS_ZP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1656618341999');
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    place-items: center;
}
.aviso-titulo{
    color:white;
    width:100%;
}