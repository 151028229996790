.tab-material {
    color: black !important;
}

.tab-material:hover {
    color: white !important;
    border-color: #617785 !important;
}

.tab-material.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color: white !important;
}
.columna-carpeta{
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.columna-carpeta::-webkit-scrollbar {
    width: 5px;
}

.columna-carpeta::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.columna-carpeta::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}