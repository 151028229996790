.contenedor-cartera {
    width: 100vw;
    height: 100vh;
    background-color: #1e3d52;
    overflow: hidden;
}

.tab-cartera {
    color: black !important;
}

.tab-cartera:hover {
    color: white !important;
}

.tab-cartera.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color:white !important;
}

.tab-cartera.cliente:hover {
    color: #1e3d52 !important;
}

.tab-cartera.active.cliente:hover {
    color: white !important;
}

.cuerpo-modal-nuevo-cliente::-webkit-scrollbar {
    width: 8px;
}

.cuerpo-modal-nuevo-cliente::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.cuerpo-modal-nuevo-cliente::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}

.columna-contenido {
    height: 50vh;
    overflow-y: scroll;
}

.columna-contenido::-webkit-scrollbar {
    width: 5px;
}

.columna-contenido::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.columna-contenido::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}



.columna-contenido-intervenciones {
    height: 100%;
    overflow-y: scroll;
}

.columna-contenido-intervenciones::-webkit-scrollbar {
    width: 5px;
}

.columna-contenido-intervenciones::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.columna-contenido-intervenciones::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}