#body-modal-edit-evento::-webkit-scrollbar {
    width: 4px;
}
 
#body-modal-edit-evento::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
#body-modal-edit-evento::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.tab-edit {
    color: black !important;
}

.tab-edit:hover {
    color: white !important;
    border-color: #617785 !important;
}

.tab-edit.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color: white !important;
}
