.contenedor-admin{
    width:100vw; 
    height:100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.container-admin{
    width: 100%;
}
.dropdown-eventos .dropdown-menu{
    border:1px solid white;
}