.list-group-item-light.list-group-item-action:hover {
    color: #e5e5e5 !important;
    background-color: #1e3d52 !important;
    border-color: transparent;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #1e3d52 !important;
    border-color: transparent;
}