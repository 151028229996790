@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-politicas{
    width: 100vw;
    height:100vh;
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/2PORTADA-POLITICAS_hpgd_CRD_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086018');
    background-repeat: no-repeat;
    background-attachment: calc();
    background-position: center center;
    background-size:cover;
    font-family: 'Helvetica', 'Arial', sans-serif;
}
.columna-politicas{
    overflow-y: scroll;
    position:relative;
    height: 80vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 10%;
}

.columna-politicas::-webkit-scrollbar {
    width: 4px;
}
 
.columna-politicas::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.columna-politicas::-webkit-scrollbar-thumb {
    background-color: #416788;
    border-radius: 100px;
}

.parrafo{
    text-align: justify;
}
.imagen-complementaria{
    position: relative;
    width: 50%;
}