.contenedor-gerencia {
    width: 100vw;
    height: 100vh;
    background-color: #1e3d52;
    padding: 2%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.fila-tabla-reporte{
    overflow-x: scroll;

}

.contenedor-gerencia::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.contenedor-gerencia::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.contenedor-gerencia::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}