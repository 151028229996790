@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.contenedor-eventos {
    width: 100%;
    height: 100%;
    background-color: #1e3d52;
}

.item-menu-ev {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.columna-eventos {
    height: 60vh;
    overflow-Y: scroll;
}

.columna-eventos::-webkit-scrollbar {
    width: 5px;
}

.columna-eventos::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.columna-eventos::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}