.contenedor-evento-detalle{
    height: 60vh;
    overflow-y: scroll;
}

.contenedor-evento-detalle::-webkit-scrollbar {
    width: 4px;
}
 
.contenedor-evento-detalle::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.contenedor-evento-detalle::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.info-col{
    height: 30vh;
    margin-bottom: 25vh;
}


.Columna-Info-Header{
    height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Columna-Info-Header::-webkit-scrollbar {
    width: 4px;
}
 
.Columna-Info-Header::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}
 
.Columna-Info-Header::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}
.tab-detalle {
    color: black !important;
}

.tab-detalle:hover {
    color: #1e3d52 !important;
}

.tab-detalle.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color:white !important;
}




.columna-admin-inscripciones .accordion-button.collapsed {
    color:white !important;
    background-color: #617785 !important;
    border-color: transparent !important;
}
.columna-admin-inscripciones .accordion-button {
    color:black !important;
    background-color: #617785 !important;
    border-color: transparent !important;
}

.columna-admin-inscripciones .accordion-item {
    background-color: transparent !important;
    border-color: transparent !important;
}

.columna-admin-inscripciones .accordion-button :focus{
    box-shadow: 0 0 0 0.25rem rgb(97,119,133/25%) !important;
}
.acordeon{
    height: 250px;
    overflow-y: scroll;
}

.acordeon::-webkit-scrollbar {
    width: 8px;
}

.acordeon::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.acordeon::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}
