.contenedor-landing {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-image: url('https://ik.imagekit.io/dmbtwqtjj/web_background_Mesa_de_trabajo_1_tQi_6omaZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658425067026');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

}
.container-landing {
    border-left: 1px solid white;
    border-right: 1px solid white;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.container-landing::-webkit-scrollbar {
    width: 0px;
}

.container-landing::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.container-landing::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.row-portada {
    width: 100%;
    margin-bottom: 50px;
}

.imagen-portada {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.row-titulo {
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.row-contacto {
    cursor: pointer;
    margin-bottom: 25px;
}


.tab-contenido {
    color: white !important;
}

.tab-contenido:hover {
    color: white !important;
    border-color: #617785 !important;
}

.tab-contenido.active {
    background-color: #617785 !important;
    border-color: #617785 !important;
    color: white !important;
}

.encabezado {
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: left;
}

.row-formulario {
    height: 360px;
    border: 2px solid white;
    border-radius: 30px;
    margin-bottom: 30px;
    background-color: #b5bad0;
    background-image: linear-gradient(0deg, #b5bad0 0%, #1e3d52 100%);
}

.promocional-formulario {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.container-encabezado {
    margin-top: 15px;
}

.row-patrocinador {
    display: flex;
    place-items: center;
    padding: 15px;
}

.col-contacto {
    display: block;
}

.row-enlace {
    border-radius: 25px;
    /* padding: 15px;*/
    position: relative;
    margin-bottom: 20px;
    display: flex;
    place-items: center;
    background-color: transparent;
}

.enlace-landing {
    text-decoration: none;
    color: white;
}

.enlace-landing:link {
    text-decoration: none;
}


.enlace-landing:visited {
    text-decoration: none;
}


.enlace-landing:hover {
    text-decoration: none;
    color: white
}


.enlace-landing:active {
    text-decoration: none;
}

@media screen and (max-width: 500px) {
    .col-contacto {
        margin-top: 0px;
    }

 

    .row-portada {
        margin-bottom: -30px;
        height: 400px;
    }

    .row-titulo {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .row-enlace {
        position: relative;
        bottom: 0%;
    }

    .container-encabezado {
        display: block;
        padding: 15%;
    }
}