#boton-submit-form {
  border: 0.5px solid #002f6c !important;
  color: #002f6c !important;
}

#boton-submit-form {
  border: 0.5px solid #002f6c !important;
  color: white !important;
  background-color: #002f6c !important;
}
