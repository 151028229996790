.contenedor-view-carpeta {
    background-color: #1e3d52;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.contenedor-archivos-carpeta {
    overflow-y: scroll;
}

.contenedor-archivos-carpeta::-webkit-scrollbar {
    width: 4px;
}

.contenedor-archivos-carpeta::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.contenedor-archivos-carpeta::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}

.contenedor-view-carpeta::-webkit-scrollbar {
    width: 4px;
}

.contenedor-view-carpeta::-webkit-scrollbar-track {
    background-color: #B5BAD0;
    border-radius: 100px;
}

.contenedor-view-carpeta::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 100px;
}